import axios, { AxiosResponse } from 'axios';
import { store } from '../../store';
import { ChatMessage } from '../../types';

const NEURODOC_PHOTO = 'go-photo-link.neurodoc.online/neurodoc-go-photo-link'
const NEURODOC_GO_TEST = 'go-service.neurodoc.online/neurodoc_go_test'

export const AnswerTest = async (key: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(
    `https://${NEURODOC_GO_TEST}/getResultTest`,

    {
      session: key,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const checkFile = async (file: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/checkFile`,

    {
      file: file,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const downloadFiles = async (
  del: boolean,
  file: string,
  id_org: number,
  type: any,
  comma: any,
  comment?: string,
  name_1?: string,
  value?: string,
  name?: string,
  id?: number,
) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/downloadFile`,
    {
      comparison: {
        comment: comment,
        name: name_1,
        value: value
      },
      comma: comma,
      del: del,
      file: file,
      id: id,
      id_organization: id_org,
      name: name,
      type: type
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const uploadMediaLibraries = async (type: string, b64: string) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_PHOTO}/uploadMediaLibraries`,
    {
      extension: type,
      file: b64
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getListDownloads = async (arr?: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  let body = {}
  if (arr) {
    body = {
      organization_id: arr
    }
  }

  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getListDownloads`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getDownloads = async (id: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getDownloads`,

    {
      id_list_downloads: id,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

interface setBodyInterface {
  start_date?: string
  stop_date?: string
  xlsx?: boolean
}

export const getStatTask = async (body: setBodyInterface) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getStatTask`,
    {
      start_date: body.start_date,
      stop_date: body.stop_date,
      xlsx: body.xlsx
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const setLibrariesReference = async (active: boolean,
  group: number,
  name: string,
  p_id: number,
  text: string,
  type: number,
  id?: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setLibrariesReference`,
    {
      active: active,
      group_theme_id: group,
      name: name,
      p_id: p_id,
      text: text,
      type: type,
      id: id,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getTaskPoint = async (taskId: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getTaskPoint`,
    {
      task_id: taskId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

interface taskStageInterface {
  testingstage_id: number
  procent: number
  max_point_stage: number
}
export const setTaskPoint = async (body: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setTaskPoint`,
    {
      ...body
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getTaskMaterial = async (toggle: boolean, taskId?: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  if (toggle) {
    const res = await axios.post(
      `https://${NEURODOC_GO_TEST}/getDucationalTrajectory`,
      {
        usertaskproceed_id: taskId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      }
    );
    return res;
  }
  if (!toggle) {
    const res = await axios.post(


      `https://${NEURODOC_GO_TEST}/getDucationalTrajectory`, {},

      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      }
    );
    return res;
  }
};

export const getChampioship = async (id_champ: number, id_user: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getChampionship`,

    {
      championship_id: id_champ,
      user_id: id_user
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const ratingProfile = async (start: any, stop: any, org: any, trn: any, toggle: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  if (toggle === 1) {
    const res = await axios.post(

      `https://${NEURODOC_GO_TEST}/getRatingProfile`,

      {
        date_start: start,
        date_stop: stop,
        trainer_id: trn
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      }
    );
    return res;
  }
  else {
    const res = await axios.post(

      `https://${NEURODOC_GO_TEST}/getRatingProfile`,
      {
        date_start: start,
        date_stop: stop,
        organization_id: org,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      }
    );
    return res;
  }
};

export const getStatMainPage = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getStatMainPage`,

    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const allUsersChampRating = async (id?: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getAllUserChampionship`,
    { user_id: id },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const allMethodological = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getLibrariesReference`,

    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const allMethodologicalTask = async (data: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getLibrariesReferenceGroupTheme`,

    {
      group_theme: data
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getTestCognitive = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getCognitiveTest`,

    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const setTestCognitive = async (data: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setCognitiveTest`,

    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getRegisterUsers = async (champ: number, type: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getRegistrationUser`,

    {
      championship_id: champ,
      type: type
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};


export const allUsers = async (login?: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getAllUsers`,

    { login: login },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getInfoChamp = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getAllChampionship`,

    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const CheckRandom = async (id: any, key: any) => {
  const jwtToken = store.getState().auth.jwtToken;

  const res = await axios.post(`https://${NEURODOC_GO_TEST}/getResultTrigger`,

    {
      usename: id,
      usertaskproceed_id: key,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getTreatmentDescription = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getTreatmentDescription`,

    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const pushPreDiagnosis = async (data: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setTaskDopPointUsertaskproceedStage3`,

    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getAssessmentProfessionalStandard = async (body: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getAssessmentProfessionalStandard`,

    {
      login: body.login, //"nrarn"
      start_date: body.start_date,
      stop_date: body.stop_date,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const countTreatmentScheme = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/countTreatmentScheme`,

    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const setTypeofPatientStatus = async (numberOfCondition: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setTypeofpatientstatusUsertaskproceed`,
    {
      typeofpatientstatus_id: numberOfCondition
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getTypeofPatientStatus = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getTypeofpatientstatusUsertaskproceed`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getTreatmentScheme = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getTreatmentScheme`,

    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const setTreatmentScheme = async (selectedIds: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setTreatmentScheme`,

    { treatment_scheme: selectedIds },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getTreatmentSchemeTaskStandartmedication = async (selectedIds: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getTreatmentSchemeTaskStandartmedication`,

    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getInformationSpecificBall = async (body: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getInformationSpecificBall`,

    {
      login: body.login,
      start_date: body.start_date,
      stop_date: body.stop_date,
      typeofdifficultlevel: null,
      universitylevelid: null,
      medicineid: body.medicineid,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getErrorPlan = async (body: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getErrorPlan`,
    {
      login: body.login, //"nrarn"
      start_date: body.start_date,
      stop_date: body.stop_date,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getAttestation = async (body: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getAttestation`,

    {
      login: body.login
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getModelPoint = async (date: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getModelPointQuarter`,

    {
      login: date.login,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const uploadMediaConsrtuctor = async (data: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_PHOTO}/uploadMediaConsrtuctor`,
    {
      extension: data.extension,
      file: data.file,
      task_id: data.task_id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

// export const getRaitingData = async (date: {start: string, end: string}) => {
//   const jwtToken = store.getState().auth.jwtToken;
//   const res = await axios.post(

//     `https://${NEURODOC_GO_TEST}/api/task/get_raiting_data',


//     {
//       start_date: date.start.length > 0 ? date.start : "2019-05-05T11:22:44.235-03:00",
//       stop_date: date.end.length > 0 ? date.end : "2023-05-05T11:22:44.235-03:00",
//       numberOfEntries: 10,
//       trainerId: 1,
//       organisationId: 2,
//       studentSpecialityId: 2,
//       universityLevelId: 1
//     },
//     {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: 'Bearer ' + jwtToken,
//       },
//     }
//   );
//   return res;
// };

export const getAnalytics = async (filter: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getAnalytics`,


    {
      difficultlevel: filter.difficultlevel || null,
      medicine: filter.medicine || null,
      organization: filter.organization || null,
      start_date: filter.start_date,
      stop_date: filter.stop_date,
      student_speciality: filter.student_speciality || null,
      task: filter.task || null,
      testingstage: filter.testingstage || null,
      trainer: filter.trainer || null,
      type: +filter.type,
      universitylevel: filter.universitylevel || null,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getDepersonalizedData = async (body: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getDepersonalizedRating`,


    {
      start_date: body.start_date,
      stop_date: body.stop_date,
      numberOfEntries: body.numberOfEntries,
      trainerId: null,
      organisationId: null,
      studentSpecialityId: null,
      universityLevelId: null,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getTeachersDescription = async (date: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getTeachersDescription`,


    {
      teachers: date.teachers,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

export const getScoreTeachers = async (data: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getScoreTeachers`,


    {
      teachers: data.teachers,
      organization_id: data.organization_id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

interface getAccreditationTest {
  doctor_info: number
  type_test: number
}
export const getAccreditationTest = async (body: getAccreditationTest) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getAccreditationTest`,


    {
      doctor_info: body.doctor_info,
      type_test: body.type_test
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

interface setAccreditationTest {
  accreditation_test_id: number
  answers_id: number[]
}
export const setAccreditationTest = async (body: setAccreditationTest) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setAccreditationTest`,


    {
      accreditation_test_id: body.accreditation_test_id,
      answers_id: body.answers_id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
};

interface getAccreditationHistoryInterface {
  user_id: number
}
export const getAccreditationHistory = async (body: getAccreditationHistoryInterface) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getAccreditationHistory`,


    {
      user_id: body.user_id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const getOrganizationDescription = async (body: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getOrganizationDescription`,


    {
      organization_id: body.organization_id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const getScoreOrganization = async (body: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getScoreOrganization`,

    {
      organization_id: body.organization_id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const getDiagnosisDescriptionsConstructor = async (body: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getDiagnosisDescriptionsConstructor`,


    {
      task_detail_id: body.task_detail_id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const descriptionsSuccess = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getDiagnosisDescriptionsSuccess`,


    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const setDiagnosisDescriptionsSuccess = async (body: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setDiagnosisDescriptionsSuccess`,


    {
      del: body.del,
      description: body.description,
      id: body.id,
      task_detail_id: body.task_detail_id
    }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + jwtToken,
    },
  }
  );
  return res;
}

export const getMediaContent = async (data: any) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_PHOTO}/get_media`,

    {
      full_file_name: data
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const descriptionsError = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getDiagnosisDescriptionsError`,


    {},

    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const getAllConclusionList = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getConclusion`,


    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}
export interface createOrUpdateConclusionInterface {
  del: boolean
  groups_themes_id: number
  id?: number
  name: string
  request_user?: string
  right_answer?: string
  text?: string
  point?: number | string
  incorrect_point?: number | string
}
export const createOrUpdateConclusion = async (body: createOrUpdateConclusionInterface) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setConclusion`,


    {
      del: body.del,
      groups_themes_id: body.groups_themes_id,
      id: body.id || undefined,
      name: body.name,
      request_user: body.request_user,
      right_answer: body.right_answer,
      text: body.text,
      point: Number(body?.point),
      incorrect_point: Number(body.incorrect_point)
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const getAllConclusionTagList = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getConclusionTag`,


    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const getAllConclusionTaskList = async (body: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getConclusionTask`,


    { task_id: body },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export type createOrUpdateConclusionType = Omit<createOrUpdateConclusionInterface, "groups_themes_id">

export const createOrUpdateConclusionTag = async (body: createOrUpdateConclusionType) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(
    `https://${NEURODOC_GO_TEST}/setConclusionTag`,

    {
      del: body.del,
      id: body.id || undefined,
      name: body.name
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}
export interface conclusionTaskTagInterface {
  conclusion_id: number
  conclusion_tag_id: number
  conclusions_tags_id: number | null
  del: boolean
  incorect_point: number
  point: number
}
export const setConclusionTaskTag = async (body: conclusionTaskTagInterface) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setConclusionTaskTag`,


    {
      conclusion_id: body.conclusion_id,
      conclusion_tag_id: body.conclusion_tag_id || undefined,
      conclusions_tags_id: body.conclusions_tags_id,
      del: body.del,
      incorect_point: body.incorect_point,
      point: body.point
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export interface IConclusionTask {
  conclusion_id: number,
  del: boolean,
  id: number | null,
  task_id: number
}

export const setConclusionTask = async (body: IConclusionTask) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setConclusionTask`,


    {
      conclusion_id: body.conclusion_id,
      del: body.del,
      id: body.id,
      task_id: body.task_id
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}


// export interface ConclusionsTask {
//   conclusion_id: number,
//   del: boolean,
//   id: number | null,
//   task_id: number
// }

export const getConclusionsTask = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getConclusionsTask`,


    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}


interface IConclusionsTask {
  conclusions_id: number
  text: string
}

export const setConlusionsTask = async (body: Array<IConclusionsTask>) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/setConclusionsTask`,


    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}


export const conlusionsTask = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/ConclusionsTask`,


    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const getTestingstageTask = async (taskId: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getTestingstageTask`,

    {

      id: taskId
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const insertTestingstageTask = async (taskId: number, stageId: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/insertTestingstageTask`,

    {
      task_id: taskId,
      testingstage_id: stageId
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}

export const deleteTestingstageTask = async (stageRelationId: number) => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/deleteTestingstageTask`,

    {
      id: stageRelationId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}


export const getTestingstageTaskOnTask = async () => {
  const jwtToken = store.getState().auth.jwtToken;
  const res = await axios.post(

    `https://${NEURODOC_GO_TEST}/getTestingstageTaskOnTask`,


    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + jwtToken,
      },
    }
  );
  return res;
}





// export const setReviewLog = async (body: reviewLogAnswers) => {
//   const jwtToken = store.getState().auth.jwtToken;
//   const res = await axios.post(



//     {
//       ans_1: body.ans_1,
//       ans_2: body.ans_2,
//       ans_3: body.ans_3,
//     },
//     {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: 'Bearer ' + jwtToken,
//       },
//     }
//   );
//   return res;
// }

export interface ChatApi {
  [x: string]: any;
  getThemesGroup(): any;
  //Консультация специалистов
  getAllSpecialists(): any;
  createSpecialist(taskId: number, id: number): any
  getCheckAnswer(id: number): any
  getAnswerSpecialists(task: number): any
  //Profile
  getAllSpecialityMy(userId: number): any
  getAllSpeciality(): any
  createSpeciality(studentSpecialityId: number, userId: number): any
  deleteSpeciality(id: number): any

  getAllTeacher(): any
  getTeacherMy(id: number): any
  createTeacher(teacherId: number, userId: number): any
  deleteTeacher(id: number): any

  getAllOrgProfile(): any
  getOrgMy(id: number): any

  getAllTrnProfile(): any
  getTrnMy(id: number): any


  getUsersMarks(id: number): any

  getUserTrainers(): any
  getRating(start: any, stop: any, id: any, org: any, trn: any, option: any): any
  //Чемпионаты
  getAllChampionships(): any;
  getRegisterChamp(id: number): any;
  saveProcced(task: number, champ: number, healing: boolean): any;
  registeredUsers(id: number): any;
  userListPoint(id: number): any;
  //ADMIN
  getAllUsersAdmin(logins?: Array<string>, trainerIds?: Array<number>, orgIds?: Array<number>): any;
  getUserAdmin(login: string): any;
  createUserAdmin(
    login: string,
    firstName: string,
    lastName: string,
    patronymic: string,
    email: string,
    imageUrl: string,
    langKey: string,
    password: string
  ): any;
  editUserAdmin(
    login: string,
    firstName: string,
    lastName: string,
    patronymic: string,
    email: string,
    imageUrl: string,
    langKey: string,
    activate: boolean
  ): any;
  savePassword(currentPassword: string, newPassword: string): any;
  resetPasswordFinish(key: string, newPassword: string): any;
  dataTable(sData: any, poData: any): any;
  getInfoRole(userId: any): any;
  createRole(userId: any): any;
  addRole(userId: number, role: any): any;
  removeRole(userId: number, role: any): any;
  addOrg(): any;
  addTrn(): any;
  allOrg(data: string | number): any;
  allTrn(data: string | number): any;
  deleteOrg(userId: any, orgId: any): any;
  deleteTrn(userId: any, orgId: any): any;
  executeOrg(id: number, answer: string, actionAnswer: boolean): any;
  executeTrn(id: number, answer: string, actionAnswer: boolean): any;
  requestOrg(userId: number, orgId: number, action: number): any;
  requestTrn(userId: number, orgId: number, action: number): any;

  listRoles(): any;
  descriptionRole(role: string): any;
  addDescription(role: string, descrip: string): any;
  updateDescription(id: number, role: string, descrip: string): any;
  deleteDescription(id: number): any;

  adminRating(): any;
  updataAdminRating(id: number, description: string, active: boolean): any

  allStudents(): any;
  allTeachers(): any;
  //////////////////////////////////////////////////////////////////////////////////
  getUserStatus(arg0: number): any;
  getUserInfo(): Promise<BaseResponse<Rating_UserInfo>>;

  loadQuestionsStress(phrasesId: number): any;
  loadQuestionsStep(phrasesId: number, questionNumber: number): any;
  loadAnalysisStress(phraseId: any): any;
  stressHistory(body: any): any;
  toggleStress(login: string): any;

  getUserRating(startTime: string, endTime: string): Promise<BaseResponse<number>>;

  getCountPatient(startTime: string, endTime: string, userId: string): Promise<BaseResponse<number>>;

  countCompleteTask(startTime: string, endTime: string): Promise<BaseResponse<number>>;

  countCompleteFailedTask(startTime: string, endTime: string): Promise<BaseResponse<number>>;

  getBestUsers(startTime: string, endTime: string): Promise<BaseResponse<Array<Rating_BestUser>>>;

  getUsergraphicLine(startTime: string, endTime: string): Promise<BaseResponse<Array<Rating_Line>>>;

  getActivities(startTime: string, endTime: string): Promise<BaseResponse<Array<Rating_Activity>>>;


  updateConstructorReferenceValue(body: Constructor_Reference) :Promise<BaseResponse<any>>
  getConstructorsTasks(): Promise<BaseResponse<Array<Constructors_Task>>>;
  updateConstructorsTasks(task: Constructors_Task_Send): Promise<BaseResponse<any>>;
  createConstructorsTasks(task: Constructors_Task_Send): Promise<BaseResponse<any>>;
  deleteConstructorsTasks(id: number): Promise<BaseResponse<any>>;
  updateConstructorsTaskDetail(illness: IIllness): Promise<BaseResponse<IIllness>>;
  createConstructorsTaskDetail(illness: IIllness): Promise<BaseResponse<IIllness>>;
  getConstructorsTaskDetail(id: number): Promise<BaseResponse<Array<any>>>;
  getConstructorsCommunications(): Promise<BaseResponse<Array<Constructors_Communication>>>;
  updateConstructorsCommunications(id: number, communication: Constructors_Communication): Promise<BaseResponse<any>>;
  createConstructorsCommunications(communication: Constructors_Communication): Promise<BaseResponse<any>>;
  deleteConstructorsCommunications(id: number): Promise<BaseResponse<any>>;

  getConstructorsPatientParams(): Promise<BaseResponse<Array<Constructors_PatientParams>>>;
  updateConstructorsPatientParams(id: number, params: Constructors_PatientParams): Promise<BaseResponse<any>>;
  createConstructorsPatientParams(params: Constructors_PatientParams): Promise<BaseResponse<any>>;
  deleteConstructorsPatientParams(id: number): Promise<BaseResponse<any>>;

  getConstructorsClinicalRelevance(): Promise<BaseResponse<Array<Constructors_ClinicalRelevance>>>;
  updateConstructorsClinicalRelevance(id: number, params: Constructors_ClinicalRelevance): Promise<BaseResponse<any>>;
  createConstructorsClinicalRelevance(params: Constructors_ClinicalRelevance): Promise<BaseResponse<any>>;
  deleteConstructorsClinicalRelevance(id: number): Promise<BaseResponse<any>>;

  getConstructorsReferenceDescription(): Promise<BaseResponse<Array<Constructors_ReferenceDescription>>>;
  updateConstructorsReferenceDescription(id: number, params: Constructors_ReferenceDescription): Promise<BaseResponse<any>>;
  createConstructorsReferenceDescription(params: Constructors_ReferenceDescription): Promise<BaseResponse<any>>;
  deleteConstructorsReferenceDescription(id: number): Promise<BaseResponse<any>>;

  getConstructorsClinicalDiagnosisAll(): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosis>>>;
  updateConstructorsClinicalDiagnosis(params: Constructors_ClinicalDiagnosis): Promise<BaseResponse<any>>;
  createConstructorsClinicalDiagnosis(params: Constructors_ClinicalDiagnosis): Promise<BaseResponse<any>>;
  deleteConstructorsClinicalDiagnosis(id: number): Promise<BaseResponse<any>>;

  getConstructorsClinicalDiagnosisMedicalDictionary(id: number): Promise<BaseResponse<Array<Constructors_ClinicalDiagnosisMedicalDictionary>>>;
  updateConstructorsClinicalDiagnosisMedicalDictionary(params: Constructors_ClinicalDiagnosisMedicalDictionary): Promise<BaseResponse<any>>;
  createConstructorsClinicalDiagnosisMedicalDictionary(params: Constructors_ClinicalDiagnosisMedicalDictionary): Promise<BaseResponse<any>>;

  getConstructorsClinicalDiagnosisInformationLevel(): Promise<BaseResponse<any>>;
  getByTaskConstructorsClinicalDiagnosisInformationLevel(id: number): Promise<BaseResponse<any>>;
  createConstructorsClinicalDiagnosisInformationLevel(params: Constructors_ClinicalDiagnosisInformationLevel): Promise<BaseResponse<any>>;
  updateByTaskConstructorsClinicalDiagnosisInformationLevel(params: Constructors_ClinicalDiagnosisInformationLevel): Promise<BaseResponse<any>>;
  deleteByTaskConstructorsClinicalDiagnosisInformationLevel(id: number): Promise<BaseResponse<any>>;

  getClinicalDiagnosisTaskProceed(): Promise<BaseResponse<any>>;

  createClinicalDiagnosisTaskProceed(clinicalDiagnosisId: number, typeOfInformationLevelId: number, preliminary_diagnosis?: boolean): Promise<BaseResponse<any>>;

  getConstructorsConnectionWithIllness(): Promise<BaseResponse<Array<Constructors_ConnectionWithIllness>>>;
  updateConstructorsConnectionWithIllness(id: number, params: Constructors_ConnectionWithIllness): Promise<BaseResponse<any>>;
  createConstructorsConnectionWithIllness(params: Constructors_ConnectionWithIllness): Promise<BaseResponse<any>>;
  deleteConstructorsConnectionWithIllness(id: number): Promise<BaseResponse<any>>;

  getConstructorsUrinePressure(): Promise<BaseResponse<Array<Constructors_UrinePressure>>>;
  updateConstructorsUrinePressure(id: number, params: Constructors_UrinePressure): Promise<BaseResponse<any>>;
  createConstructorsUrinePressure(params: Constructors_UrinePressure): Promise<BaseResponse<any>>;
  deleteConstructorsUrinePressure(id: number): Promise<BaseResponse<any>>;

  getConstructorsUrineColor(): Promise<BaseResponse<Array<Constructors_UrineColor>>>;
  updateConstructorsUrineColor(id: number, params: Constructors_UrineColor): Promise<BaseResponse<any>>;
  createConstructorsUrineColor(params: Constructors_UrineColor): Promise<BaseResponse<any>>;
  deleteConstructorsUrineColor(id: number): Promise<BaseResponse<any>>;

  getConstructorsUrineImpurity(): Promise<BaseResponse<Array<Constructors_UrineImpurity>>>;
  updateConstructorsUrineImpurity(id: number, params: Constructors_UrineImpurity): Promise<BaseResponse<any>>;
  createConstructorsUrineImpurity(params: Constructors_UrineImpurity): Promise<BaseResponse<any>>;
  deleteConstructorsUrineImpurity(id: number): Promise<BaseResponse<any>>;

  getConstructorsUrineSmell(): Promise<BaseResponse<Array<Constructors_UrineSmell>>>;
  updateConstructorsUrineSmell(id: number, params: Constructors_UrineSmell): Promise<BaseResponse<any>>;
  createConstructorsUrineSmell(params: Constructors_UrineSmell): Promise<BaseResponse<any>>;
  deleteConstructorsUrineSmell(id: number): Promise<BaseResponse<any>>;

  getConstructorsUrineTransparency(): Promise<BaseResponse<Array<Constructors_UrineTransparency>>>;
  updateConstructorsUrineTransparency(id: number, params: Constructors_UrineTransparency): Promise<BaseResponse<any>>;
  createConstructorsUrineTransparency(params: Constructors_UrineTransparency): Promise<BaseResponse<any>>;
  deleteConstructorsUrineTransparency(id: number): Promise<BaseResponse<any>>;

  getConstructorsUrineFoam(): Promise<BaseResponse<Array<Constructors_UrineFoam>>>;
  updateConstructorsUrineFoam(id: number, params: Constructors_UrineFoam): Promise<BaseResponse<any>>;
  createConstructorsUrineFoam(params: Constructors_UrineFoam): Promise<BaseResponse<any>>;
  deleteConstructorsUrineFoam(id: number): Promise<BaseResponse<any>>;

  getConstructorsUrineComfort(): Promise<BaseResponse<Array<Constructors_UrineComfort>>>;
  updateConstructorsUrineComfort(id: number, params: Constructors_UrineComfort): Promise<BaseResponse<any>>;
  createConstructorsUrineComfort(params: Constructors_UrineComfort): Promise<BaseResponse<any>>;
  deleteConstructorsUrineComfort(id: number): Promise<BaseResponse<any>>;

  getConstructorsUrineAdditionalFenomen(): Promise<BaseResponse<Array<Constructors_UrineAdditionalFenomen>>>;
  updateConstructorsUrineAdditionalFenomen(id: number, params: Constructors_UrineAdditionalFenomen): Promise<BaseResponse<any>>;
  createConstructorsUrineAdditionalFenomen(params: Constructors_UrineAdditionalFenomen): Promise<BaseResponse<any>>;
  deleteConstructorsUrineAdditionalFenomen(id: number): Promise<BaseResponse<any>>;

  getConstructorsExcrementColor(): Promise<BaseResponse<Array<Constructors_ExcrementColor>>>;
  updateConstructorsExcrementColor(id: number, params: Constructors_ExcrementColor): Promise<BaseResponse<any>>;
  createConstructorsExcrementColor(params: Constructors_ExcrementColor): Promise<BaseResponse<any>>;
  deleteConstructorsExcrementColor(id: number): Promise<BaseResponse<any>>;

  getConstructorsExcrementComfort(): Promise<BaseResponse<Array<Constructors_ExcrementComfort>>>;
  updateConstructorsExcrementPhoto(id: number, params: Constructors_ExcrementComfort): Promise<BaseResponse<any>>;
  createConstructorsExcrementComfort(params: Constructors_ExcrementComfort): Promise<BaseResponse<any>>;
  deleteConstructorsExcrementComfort(id: number): Promise<BaseResponse<any>>;

  getConstructorsExcrementPhoto(): Promise<BaseResponse<Array<Constructors_ExcrementPhoto>>>;
  updateConstructorsExcrementPhoto(id: number, params: Constructors_ExcrementPhoto): Promise<BaseResponse<any>>;
  createConstructorsExcrementPhoto(params: Constructors_ExcrementPhoto): Promise<BaseResponse<any>>;
  deleteConstructorsExcrementPhoto(id: number): Promise<BaseResponse<any>>;

  getConstructorsExcrementSmell(): Promise<BaseResponse<Array<Constructors_ExcrementSmell>>>;
  updateConstructorsExcrementSmell(id: number, params: Constructors_ExcrementSmell): Promise<BaseResponse<any>>;
  createConstructorsExcrementSmell(params: Constructors_ExcrementSmell): Promise<BaseResponse<any>>;
  deleteConstructorsExcrementSmell(id: number): Promise<BaseResponse<any>>;

  // createOrganizationByTask(params: Constructors_OrganizationByTask): Promise<BaseResponse<any>>;


  getResearchUnit(): Promise<BaseResponse<Array<Type_ResearchUnit>>>;
  getResearchDefault(id: number): Promise<BaseResponse<any>>;
  createResearchDefault(body: any): Promise<BaseResponse<any>>;
  updateResearchDefault(id: number, body: any): Promise<BaseResponse<any>>;
  createParametersForDefaultResearch(body: any): Promise<BaseResponse<any>>;
  deleteParametersForDefaultResearch(id: any): Promise<BaseResponse<any>>;
  getParametersForDefaultResearch(id: number): Promise<BaseResponse<Array<Type_ParametersForDefaultResearch>>>;
  getResearchDefaultByTypeId(id: number): Promise<BaseResponse<Array<Type_ResearchDefaultByTypeId>>>;

  getConstructorsInspectionside(): Promise<BaseResponse<Array<any>>>;
  getConstructorsPartOfBody(): Promise<BaseResponse<Array<any>>>;
  getConstructorsInspectionMethods(): Promise<BaseResponse<Array<any>>>;
  getConstructorsPointsOfView(taskId: number): Promise<BaseResponse<Array<PointOfView_GetResponse_NetData>>>;
  createConstructorsPointsOfView(pointsOfView: PointsOfView_Props): Promise<BaseResponse<Array<any>>>;
  updateConstructorsPointsOfView(pointsOfView: PointsOfView_Props): Promise<BaseResponse<Array<any>>>;
  deleteConstructorsPointsOfView(id: number): Promise<BaseResponse<Array<any>>>;

  getUserTaskProceedJoint(): Promise<BaseResponse<any>>;
  createRheumatologyState(body: Rheumatology_State): Promise<BaseResponse<any>>;
  getRheumatologyState(id: number): Promise<BaseResponse<any>>;
  getRheumatologyUserTaskProceedState(): Promise<BaseResponse<any>>;
  createRheumatologyUserTaskProceedState(state: number): Promise<BaseResponse<any>>;
  updateRheumatologyState(body: Rheumatology_State): Promise<BaseResponse<any>>;
  deleteRheumatologyState(id: number): Promise<BaseResponse<any>>;
  createCustomPointOfView(pointOfView: PointsOfView_Props): Promise<BaseResponse<Array<any>>>;
  updateCustomPointOfView(pointOfView: PointsOfView_Props): Promise<BaseResponse<Array<any>>>;
  deleteCustomPointOfView(id: number): Promise<BaseResponse<Array<any>>>;

  getConstructorsBodyPartImage(id: number): Promise<BaseResponse<Array<any>>>;
  createConstructorsBodyPartImage(bodyPart: BodyPartImage_Props): Promise<BaseResponse<Array<any>>>;
  updateConstructorsBodyPartImage(bodyPart: BodyPartImage_Props): Promise<BaseResponse<Array<any>>>;
  deleteConstructorsBodyPartImage(id: number): Promise<BaseResponse<Array<any>>>;
  getConstructorsTypeOfResearch(): Promise<BaseResponse<Array<any>>>;

  getConstructorsDescriptionList(id: number): Promise<BaseResponse<Type_DescriptionList>>;
  createConstructorsDescriptionList(body: Type_DescriptionList): Promise<BaseResponse<Type_DescriptionList>>;
  updateConstructorsDescriptionList(
    id: number,
    body: Type_DescriptionList
  ): Promise<BaseResponse<Type_DescriptionList>>;
  deleteConstructorsDescriptionList(id: number): Promise<BaseResponse<Type_DescriptionList>>;

  updateConstructorsTypeOfResearch(id: number, typeOfResearch: Constructors_Additional): Promise<BaseResponse<any>>;
  createConstructorsTypeOfResearch(typeOfResearch: Constructors_Additional): Promise<BaseResponse<any>>;
  deleteConstructorsTypeOfResearch(id: number): Promise<BaseResponse<any>>;
  getConstructorsGroupTypeResearch(id: number): Promise<BaseResponse<Array<any>>>;
  getConstructorsDiagnose(): Promise<BaseResponse<Array<any>>>;
  getConstructorsMedication(): Promise<BaseResponse<Array<any>>>;
  getConstructorsNonMedication(): Promise<BaseResponse<Array<any>>>;
  getConstructorsObjectiveStatus(): Promise<BaseResponse<Array<any>>>;
  getUniversityLevel(): Promise<BaseResponse<Array<University_Level>>>;
  getPatientModel(): Promise<BaseResponse<Array<Patient_Model>>>;
  getTypeOfPatientStatus(): Promise<BaseResponse<Array<Type_Of_Patient_Status>>>;
  getTest(body: bodyGetTest): Promise<BaseResponse<Get_Test>>;
  setTestAnswers(body: set_Test_Answers): Promise<BaseResponse<Array<set_Test_Answers>>>;
  sendMessage(message: string): Promise<BaseResponse<SendMessage_ResponseData>>;

  getChatById(chatId: string): Promise<ChatMessage[]>;

  authenticate(request: AuthenticateRequest): Promise<AxiosResponse<JwtToken>>;

  registration(request: RegistrationRequest): Promise<void>;

  resetPasswordByEmail(email: string): Promise<BaseResponse<any>>;

  setNewPassword(password: string): Promise<void>;

  updateUserProfile(name: string, lastName: string, phone: string): Promise<void>;

  getCurrentJournalHistory(): Promise<BaseResponse<Array<JournalHistory_ResponseData>>>;

  startTask(taskId: number): Promise<BaseResponse<TaskProceed_ResponseData>>;

  startTask1(taskId: number, championship_id?: number): Promise<BaseResponse<TaskProceed_ResponseData>>;

  finishTask(): Promise<BaseResponse<TaskProceed_ResponseData>>;

  unfinishedTask(): Promise<BaseResponse<TaskProceed_ResponseData>>;

  setTaskRating(grade: number, description: string): Promise<BaseResponse<TaskRating_ResponseData>>;

  loadMedicine(): Promise<BaseResponse<Array<Medicine_ResponseData>>>;

  loadTypesOfDifficultLevels(): Promise<BaseResponse<Array<TypeOfDifficultLevel_ResponseData>>>;

  loadTasks(): Promise<BaseResponse<Array<Task_ResponseData>>>;

  loadIllnessSeverity(): Promise<BaseResponse<Array<any>>>;

  loadIllnessRisk(): Promise<BaseResponse<Array<any>>>;

  loadIllnessStage(): Promise<BaseResponse<Array<any>>>;

  loadIllnessPhase(): Promise<BaseResponse<Array<any>>>;

  loadTasksBy(
    universityLevelId: number,
    medicineId: number,
    typeOfDifficultLevelId: number
  ): Promise<BaseResponse<Array<Task_ResponseData>>>;

  loadTaskById(id: number): Promise<BaseResponse<Task_ResponseData>>;

  loadCurrentCheckHistory(): Promise<BaseResponse<Array<CheckJournalHistory_ResponseData>>>;

  checkObjectiveState(message: string): Promise<BaseResponse<SendMessage_ResponseData>>;

  getGroupsOfResearchByParentId(parentId: number): Promise<BaseResponse<Array<GroupOfResearch_ResponseData>>>;

  getGroupOfResearchWithTypes(groupId: number): Promise<BaseResponse<GroupOfResearch_ResponseData>>;

  getResearchResults(
    researches: Array<ResearchItem_RequestData>
  ): Promise<BaseResponse<Array<ResearchResults_ResponseData>>>;

  getCurrrentTaskResearchResults(): Promise<BaseResponse<Array<ResearchResults_ResponseData>>>;

  getTaskResults(): Promise<BaseResponse<TaskResult_ResponseData>>;

  getDiagnosisTypes(): Promise<BaseResponse<Array<DiagnosisTypes_ResponseData>>>;

  pushDisgnosisResult(reqData: Diagnosis_ReqData): Promise<BaseResponse<any>>;

  getDiagnosisForms(): Promise<BaseResponse<DiagnosisFrom_ResponseData>>;

  increaseTaskStage(): Promise<BaseResponse<any>>;
  increaseSetTaskStage(id: number | undefined): Promise<BaseResponse<number | undefined>>
  increaseToTherapyTaskStage(): Promise<BaseResponse<any>>;

  searchResearch(groupId: number, text: string): Promise<BaseResponse<TypeOfResearchSearchResult_Response>>;

  // получить список форм выпуска
  getReleaseForms(): Promise<BaseResponse<Array<ReleaseForm>>>;

  // получить группу лекарств
  getGroupOfMedicine(): Promise<BaseResponse<Array<GroupOfMedicine>>>;

  // получить способы приема лекарств
  getReceptionMethods(): Promise<BaseResponse<Array<ReceptionMethod>>>;

  // получить возможные времена приема лекарств
  getTimesOfReceipt(): Promise<BaseResponse<Array<TimeOfReceipt>>>;

  // получить возможные связи лекарства с приемом пищи
  getConnectionWithFoods(): Promise<BaseResponse<Array<ConnectionWithFood>>>;

  // получить возможные связи лекарства со сном
  getConnectionWithSleep(): Promise<BaseResponse<Array<ConnectionWithSleep>>>;

  // получить возможные доп ограничения
  getAdditionalRestriction(): Promise<BaseResponse<Array<AdditionalRestriction>>>;

  // получить возможные единицы измерения периода приема лекаства
  getReceptionPeriod(): Promise<BaseResponse<Array<ReceptionPeriod_NetData>>>;

  // получить пункт назначенного лечения
  getPrescribedMedicationByTaskId(
    taskProceedId: number
  ): Promise<BaseResponse<Array<PrescribedMedication__ResponseData>>>;

  // назначить (создать) лечение
  createPrescribedMedication(medication: PrescribedMedication__NetData): Promise<BaseResponse<any>>;

  // редактировать лечение
  editPrescribedMedication(id: number, medication: PrescribedMedication__NetData): Promise<BaseResponse<any>>;

  // получить результат медикаментозного лечения
  getMedicalResult(): Promise<BaseResponse<MedicalResult_ResponseData>>;

  // получить список возможных диет (не мед.)
  getDietTypes(): Promise<BaseResponse<Array<DietType_ResponseData>>>;

  // получить список возможных единиц дозировки (не мед.)
  getDosageUnits(): Promise<BaseResponse<Array<DosageUnit_ResponseData>>>;

  // Получение списка назначенных немедикаментозных лечений для taskProceed
  getNotMedicalMedication(taskProceedId: number): Promise<BaseResponse<Array<NotMedicalMedication_NetData>>>;

  // Создание немедикаментозного лечения
  createNotMedicalMedication(medication: NotMedicalMedication_NetData): Promise<BaseResponse<any>>;

  // Редактирование немедикаментозного лечения
  editNotMedicalMedication(id: number, medication: NotMedicalMedication_NetData): Promise<BaseResponse<any>>;

  // получать результат немедикаментозного лечения
  getNotMedicalResult(): Promise<BaseResponse<Array<PrescribedMedicationResult__ResponseData>>>;

  // апи получаения флагов немедикаментозного лечения
  getNotMedicalFlags(): Promise<BaseResponse<Array<NotMedicalFlag_ResponseData>>>;

  // апи получения типов немедикаментозного лечения
  getNotMedicalTypes(): Promise<BaseResponse<Array<NotMedicalType_ResponseData>>>;

  // получить список назначенных диет
  getDietMedicalItems(): Promise<BaseResponse<Array<DietMedication_GetResponse_NetData>>>;

  // создать назначение лечения в виде диеты
  createDietMedication(diet: DietMedication_CreateUpdateRequest_NetData): Promise<BaseResponse<void>>;

  // обновить назначение диеты
  updateDietMedication(id: number, data: DietMedication_CreateUpdateRequest_NetData): Promise<BaseResponse<void>>;

  // Удалить диету
  deleteDietMedication(id: number): Promise<BaseResponse<void>>;

  // получить список назначенных лечений-режимов для текущей попытки задачи
  getScheduleMedicationItems(): Promise<BaseResponse<Array<ScheduleMedication_GetResponse_NetData>>>;

  // создать назначить лечение-режим
  createScheduleMedication(data: ScheduleMedication_CreateUpdate_NetData): Promise<BaseResponse<void>>;

  // обновить назначенное лечение-режим
  updateScheduleMedication(id: number, data: ScheduleMedication_CreateUpdate_NetData): Promise<BaseResponse<void>>;

  getTargetUseTypes(): Promise<BaseResponse<Array<TargetUseType_NetData>>>;

  // лучение - получить список назначенных продуктов для текущей задачи
  getFoodMedicationsItems(): Promise<BaseResponse<Array<FoodMedication_GetResponse_NetData>>>;

  // лечение - назначить продукт
  createFoodMedication(data: FoodMedication_CreateUpdate_NetData): Promise<BaseResponse<void>>;

  // лечение - обновить назначенный продукт по id
  updateFoodMedication(id: number, data: FoodMedication_CreateUpdate_NetData): Promise<BaseResponse<void>>;

  // получить список видов альтерантивного лечения
  getAlternativeMedicationType(): Promise<BaseResponse<Array<AlternativeMedicationType_GetResponse>>>;

  // создать активное лечение
  createActiveMedication(data: ActiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>>;

  // обновить активное лечение
  updateActiveMedication(id: number, data: ActiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>>;

  // Получить активное лечение
  getActiveMedications(): Promise<BaseResponse<Array<ActiveMedication_GetResponse_NetData>>>;

  // создать пассивное лечение
  createPassiveMedication(data: PassiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>>;

  // обновить пассивное лечение
  updatePassiveMedication(id: number, data: PassiveMedication_CreateUpdate_NetData): Promise<BaseResponse<void>>;

  // Получить пассивное лечение
  getPassiveMedications(): Promise<BaseResponse<Array<PassiveMedication_GetResponse_NetData>>>;

  // Варианты ответа для пассивного и активного лечения на легкой сложности (типо массаж.. и тд)
  getAlternativeTreatment(): Promise<BaseResponse<Array<PassiveActiveTreatment_GetResponse_NetData>>>;

  // получить результаты пассивного лечения
  getPassiveMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>>;

  // получить результаты активного лечения
  getActiveMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>>;

  // получить результаты назначенной диеты
  getDietMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>>;

  // получить результаты назначенных продуктов
  getFoodMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>>;

  // получить результаты назначенных режимов
  getScheduleMedicationsResults(): Promise<BaseResponse<MedicalResult_ResponseData>>;

  // получить все возможные стороны осмотра
  getInspectionSides(): Promise<BaseResponse<Array<InspectionSide_GetResponse_NetData>>>;

  // получить список частей тела в рамках задачи
  getPartsOfBody(): Promise<BaseResponse<Array<PartOfBody_GetResponse_NetData>>>;

  // получить список методов осмотра
  getInspectionMethods(): Promise<BaseResponse<Array<InspectionMethod_GetResponse_NetData>>>;

  // получить список точек для данной части тела и данной стороны осмотра и данного метода осмотра
  getPointsOfView(
    inspectionsideid: string,
    partofbodyid: string,
    inspectionmethodid: string
  ): Promise<BaseResponse<Array<PointOfView_GetResponse_NetData>>>;

  // получить изображение для данной части тела и данной стороны осмотра и данного метода осмотра
  getBodyPartImage(
    inspectionsideid: string,
    partofbodyid: string,
    inspectionmethodid: string,
    inspectionTask: string
  ): Promise<BaseResponse<Array<BodyPartImage_GetResponse_NetData>>>;

  getAllRheumatologyState(): Promise<BaseResponse<any>>


  // отметить просмотренную точку
  markPointClicked(pointId: number): Promise<BaseResponse<any>>;

  getAnamnesis(): Promise<BaseResponse<Array<type_Anamnesis>>>;

  getConnectionWithIllness(): Promise<BaseResponse<Array<get_Connection_With_Illness>>>;

  getDefaultMeanList(id: number | undefined): Promise<BaseResponse<Array<get_Default_Mean_List>>>;

  createMean(body: create_Mean): Promise<BaseResponse<create_Mean>>;

  getAnamnesisType(id: number): Promise<BaseResponse<Array<type_Anamnesis>>>;

  updateMeaning(body: update_Meaning): Promise<BaseResponse<update_Meaning>>;

  deleteMeaning(id: number | undefined): Promise<BaseResponse<delete_Meaning>>;

  getPatientConscious(): Promise<BaseResponse<any>>;

  getPatientState(): Promise<BaseResponse<Array<any>>>;

  getObjectiveStatus(id: number): Promise<BaseResponse<Array<get_Objective_Status>>>;
  createObjectiveStatus(body: any): Promise<BaseResponse<any>>;
  getCurrentObjectiveStatus(id: number): Promise<BaseResponse<get_Current_Objective_Status>>;

  getExcrementPhoto(): Promise<BaseResponse<Array<get_Excrement_Photo>>>;

  getExcrementComfort(): Promise<BaseResponse<Array<get_Excrement_Photo>>>;

  getExcrementColor(): Promise<BaseResponse<Array<get_Excrement_Photo>>>;

  getExcrementSmell(): Promise<BaseResponse<Array<get_Excrement_Photo>>>;

  getUrinePressure(): Promise<BaseResponse<Array<get_Urine_Status>>>;
  getUrineColor(): Promise<BaseResponse<Array<get_Urine_Status>>>;
  getUrineSmell(): Promise<BaseResponse<Array<get_Urine_Status>>>;
  getUrineImpurity(): Promise<BaseResponse<Array<get_Urine_Status>>>;
  getUrineTransparency(): Promise<BaseResponse<Array<get_Urine_Status>>>;
  getUrineFoam(): Promise<BaseResponse<Array<get_Urine_Status>>>;
  getUrineComfort(): Promise<BaseResponse<Array<get_Urine_Status>>>;
  getUrineAdditionalFenomen(): Promise<BaseResponse<Array<get_Urine_Status>>>;

  getAllAnamnesisList(): Promise<BaseResponse<Array<get_Connection_With_Illness>>>;

  getInspection(id: number | undefined): Promise<BaseResponse<Array<PointOfView_GetResponse_NetData>>>;
  getFrontBodyPartImage(
    partOfBodyId: string | undefined,
    inspectionMethodId: string | undefined,
    id: string | undefined
  ): Promise<BaseResponse<Array<get_Front_Body_Part_Image>>>;
  getLocalisationsPlace(): Promise<BaseResponse<Array<get_localisations_place>>>;
  getStandardAlternativeMedication(
    idTask: number,
    method: number
  ): Promise<BaseResponse<Array<get_Standart_Alternative_Medication>>>;
  getStandardMedication(id: number): Promise<BaseResponse<Array<get_Medical_Treatment>>>;
  getConstructorAdditionalResults(id: number): Promise<BaseResponse<Array<get_Constructor_Additional_Results>>>;
  getConstructorPastResearch(id: number): Promise<BaseResponse<any>>;
  getConstructorPastSpecialist(id: number): Promise<BaseResponse<any>>;
  getTaskProceedPastResearch(): Promise<BaseResponse<any>>;
  getTaskProceedPastSpecialist(): Promise<BaseResponse<any>>;
  updateConstructorPastResearch(id: number): Promise<BaseResponse<any>>;
  updateConstructorPastSpecialist(id: number): Promise<BaseResponse<any>>;

  updateCurrentObjectiveStatus(
    body: update_Current_Objective_status
  ): Promise<BaseResponse<update_Current_Objective_status>>;

  getMedicalDictionaryAll(): Promise<BaseResponse<Array<get_Medical_Dictionary_All>>>;

  getReference(): Promise<BaseResponse<Array<get_Reference>>>;

  getMedicalDictionaryFromId(id: number): Promise<BaseResponse<get_Medical_Dictionary>>;

  getMedicationDictionaryByText(text: string): Promise<BaseResponse<Array<get_Medical_Dictionary>>>;

  updateConstructorAdditionalResult(
    body: Update_Research_Instrument
  ): Promise<BaseResponse<Update_Research_Instrument>>;

  deleteConstructorAdditionalResult(id: number): Promise<BaseResponse<any>>;
  createSchedule(
    schedule: ScheduleMedication_CreateUpdate_NetData
  ): Promise<BaseResponse<ScheduleMedication_CreateUpdate_NetData>>;
  updateSchedule(schedule: ScheduleMedication_CreateUpdate_NetData): Promise<BaseResponse<Create_Or_Update_Schedule>>;
  deleteSchedule(id: number): Promise<BaseResponse<any>>;

  createDiet(diet: Add_Diet): Promise<BaseResponse<Add_Diet>>;
  updateDiet(diet: Add_Diet): Promise<BaseResponse<Add_Diet>>;
  deleteDiet(id: number): Promise<BaseResponse<any>>;

  createNotTreatmentPassive(treatment: CreateOrUpdateNotTreatment): Promise<BaseResponse<CreateOrUpdateNotTreatment>>;
  updateNotTreatmentPassive(treatment: CreateOrUpdateNotTreatment): Promise<BaseResponse<CreateOrUpdateNotTreatment>>;
  deleteNotTreatmentPassive(id: number): Promise<BaseResponse<any>>;

  createNotTreatmentActive(treatment: CreateOrUpdateNotTreatment): Promise<BaseResponse<CreateOrUpdateNotTreatment>>;
  updateNotTreatmentActive(treatment: CreateOrUpdateNotTreatment): Promise<BaseResponse<CreateOrUpdateNotTreatment>>;
  deleteNotTreatmentActive(id: number): Promise<BaseResponse<any>>;

  createMedicationTreatment(treatment: Treatment_Item_Type): Promise<BaseResponse<Treatment_Item_Type>>;
  updateMedicationTreatment(treatment: Treatment_Item_Type): Promise<BaseResponse<Treatment_Item_Type>>;
  deleteMedicationTreatment(id: number): Promise<BaseResponse<any>>;

  createResearchInstrument(research: createResearchInstrument): Promise<BaseResponse<createResearchInstrument>>;
  updateResearchInstrument(research: createResearchInstrument): Promise<BaseResponse<createResearchInstrument>>;

  createResearchLab(research: createResearchInstrument): Promise<BaseResponse<createResearchInstrument>>;
  updateResearchLab(research: createResearchInstrument): Promise<BaseResponse<createResearchInstrument>>;

  getSituationList(): Promise<BaseResponse<get_Inspection_Side>>;

  getRandomTask(): Promise<BaseResponse<Task_ResponseData>>;

  getCompetenceAll(): Promise<BaseResponse<CompetenceType>>;
  getCurrentCompetence(id: number): Promise<BaseResponse<CompetenceType>>;
  deleteCompetence(id: number): Promise<BaseResponse<any>>;
  updateCompetence(body: CompetenceType): Promise<BaseResponse<CompetenceType>>;
  createCompetence(body: string): Promise<BaseResponse<CompetenceType>>;

  getAllSpecialties(): Promise<BaseResponse<SpecialtiesInterface>>;
  getCurrentSpecialties(id: number): Promise<BaseResponse<SpecialtiesInterface>>;
  deleteCurrentSpecialties(id: number): Promise<BaseResponse<SpecialtiesInterface>>;
  createCurrentSpecialties(body: SpecialtiesInterface): Promise<BaseResponse<SpecialtiesInterface>>;
  updateCurrentSpecialties(body: SpecialtiesInterface): Promise<BaseResponse<SpecialtiesInterface>>;

  getTypeOfMedicalDictionaryAll(): Promise<BaseResponse<any>>;

  getAllMedicalDictionaryList(): Promise<BaseResponse<get_Medical_Dictionary_All>>;
  createMedicalDictionary(body: any): Promise<BaseResponse<any>>;
  deleteMedicalDictionary(id: number): Promise<BaseResponse<any>>;
  updateMedicalDictionary(body: any): Promise<BaseResponse<any>>;

  getAllTypeMedicalDictionaryList(): Promise<BaseResponse<get_Medical_Dictionary_All>>;
  createTypeMedicalDictionary(body: any): Promise<BaseResponse<any>>;
  deleteTypeMedicalDictionary(id: number): Promise<BaseResponse<any>>;
  updateTypeMedicalDictionary(body: any): Promise<BaseResponse<any>>;

  getModesAll(): Promise<BaseResponse<modesReferenceInterface>>;
  createMode(body: modesReferenceInterface): Promise<BaseResponse<modesReferenceInterface>>;
  updateMode(body: modesReferenceInterface): Promise<BaseResponse<modesReferenceInterface>>;
  deleteMode(id: number): Promise<BaseResponse<any>>;

  getNursingCareAll(): Promise<BaseResponse<nursingCareInterface>>;
  updateNursingCare(body: nursingCareInterface): Promise<BaseResponse<nursingCareInterface>>;
  createNursingCare(body: nursingCareInterface): Promise<BaseResponse<nursingCareInterface>>;
  deleteNursingCare(id: number): Promise<BaseResponse<number>>;
  getNursingCareResult(): Promise<BaseResponse<nursingCareListInterface[]>>
  getNursingCareAlternativeList(): Promise<BaseResponse<any[]>>
  createNursingCareTreatment(body: nursingCareInterface): Promise<BaseResponse<nursingCareInterface>>
  updateConstructorNursingCare(body: nursingCareListInterface): Promise<BaseResponse<nursingCareListInterface>>

  getSurgeonTreatment(): Promise<BaseResponse<nursingCareInterface>>;
  updateSurgeonTreatment(body: nursingCareInterface): Promise<BaseResponse<nursingCareInterface>>;
  createSurgeonTreatment(body: nursingCareInterface): Promise<BaseResponse<nursingCareInterface>>;
  deleteSurgeonTreatment(id: number): Promise<BaseResponse<number>>;
  updateConstructorSurgeonTreatment(body: surgicalTreatmentConstructorInterface): Promise<BaseResponse<surgicalTreatmentConstructorInterface>>
  createConstructorSurgeonTreatment(body: surgicalTreatmentConstructorInterface): Promise<BaseResponse<surgicalTreatmentConstructorInterface>>
  deleteConstructorSurgeonTreatment(id: number): Promise<BaseResponse<number>>

  getModulesAll(): Promise<BaseResponse<Medicine_ResponseData>>;
  updateModules(body: Medicine_ResponseData): Promise<BaseResponse<Medicine_ResponseData>>;
  createModules(body: Medicine_ResponseData): Promise<BaseResponse<Medicine_ResponseData>>;
  deleteModules(id: number): Promise<BaseResponse<number>>;

  getThemesAll(): Promise<BaseResponse<themeInterface[]>>
  getCurrentTheme(id: number): Promise<BaseResponse<themeInterface>>
  deleteTheme(id: number): Promise<BaseResponse<number>>
  createTheme(theme: themeInterface): Promise<BaseResponse<themeInterface>>
  updateTheme(theme: themeInterface): Promise<BaseResponse<themeInterface>>

  getNoneMedicalTreatmentAll(): Promise<BaseResponse<modesReferenceInterface>>;
  createNoneMedicalTreatment(body: modesReferenceInterface): Promise<BaseResponse<modesReferenceInterface>>;
  updateNoneMedicalTreatment(body: modesReferenceInterface): Promise<BaseResponse<modesReferenceInterface>>;
  deleteNoneMedicalTreatment(id: number): Promise<BaseResponse<number>>;

  getNoneMedicalTreatmentTypeAll(): Promise<BaseResponse<modesReferenceInterface>>;
  createNoneMedicalTreatmentType(body: modesReferenceInterface): Promise<BaseResponse<modesReferenceInterface>>;
  updateNoneMedicalTreatmentType(body: modesReferenceInterface): Promise<BaseResponse<modesReferenceInterface>>;
  deleteNoneMedicalTreatmentType(id: number): Promise<BaseResponse<number>>;

  getMatrixCompetenceAll(): Promise<BaseResponse<matrixCompetenceInterface>>;
  createMatrixCompetence(body: matrixCompetenceInterface): Promise<BaseResponse<matrixCompetenceInterface>>;

  depersonalizedRatingData(body: depersonalizedRatingInterface): Promise<BaseResponse<depersonalizedRatingInterface>>

  getAllDoctorsType(): Promise<BaseResponse<SpecialtiesInterface[]>>

  getTreatmentSchemeById(id: number): Promise<BaseResponse<treatmentSchemeInterface[]>>
  createTreatmentScheme(treatment: treatmentSchemeInterface): Promise<BaseResponse<treatmentSchemeInterface>>
  deleteTreatmentScheme(taskId: number, schemeId: number): Promise<BaseResponse<number>>
  updateTreatmentScheme(treatment: treatmentSchemeInterface): Promise<BaseResponse<treatmentSchemeInterface>>
  getAllTreatmentScheme(): Promise<BaseResponse<treatmentSchemeInterface[]>>

  getAllGroupOfResearch(): Promise<BaseResponse<groupOfResearchInterface[]>>
  getCurrentGroupOfResearch(id: number): Promise<BaseResponse<groupOfResearchInterface[]>>
  createResearchElement(body: groupOfResearchInterface): Promise<BaseResponse<groupOfResearchInterface>>
  deleteCurrentGroupOfResearch(id: number): Promise<BaseResponse<number>>
  createResearchGroup(body: any): Promise<BaseResponse<any>>
  deleteResearchGroup(id: number): Promise<BaseResponse<number>>
  updateResearchGroup(body: groupOfResearchInterface): Promise<BaseResponse<groupOfResearchInterface>>

  teacherReviewGetAll(body: any): Promise<BaseResponse<any>>
  teacherReviewCreate(body: reviewCreate): Promise<BaseResponse<reviewCreate>>
  deleteReviewTeacher(id: number): Promise<BaseResponse<number>>

  getAllPatients(): Promise<BaseResponse<allPatient[]>>
  createPatient(body: allPatient): Promise<BaseResponse<allPatient>>
  updatePatient(body: allPatient): Promise<BaseResponse<allPatient>>
  deletePatientModel(id: number): Promise<BaseResponse<number>>

  getAllModel(): Promise<BaseResponse<models[]>>
  createModel(body: models): Promise<BaseResponse<models>>
  updateModel(body: models): Promise<BaseResponse<models>>

  getPatientModelParameters(id: number): Promise<BaseResponse<any>>
  createPatientModelParameters(body: any): Promise<BaseResponse<any>>
  deletePatientModelParameters(id: number): Promise<BaseResponse<number>>


  finishTaskConstructor(id: number, disabled?: boolean): Promise<BaseResponse<number>>

  getNoGroupType(): Promise<BaseResponse<any>>

  getSpecialistsAdvice(): Promise<BaseResponse<any>>

  getSpecialistAdviceByTask(id: number): Promise<BaseResponse<createdSpecialist[]>>
  createSpecialistAdvice(body: createdSpecialist): Promise<BaseResponse<createdSpecialist>>
  deleteSpecialistAdvice(id: number): Promise<BaseResponse<number>>
  updateSpecialistAdvice(body: createdSpecialist): Promise<BaseResponse<createdSpecialist>>

  deleteDietInConstructorTask(id: number): Promise<BaseResponse<number>>
  deleteNursingByTaskCare(id: number): Promise<BaseResponse<number>>
  createNursingByTaskCare(body: any): Promise<BaseResponse<any>>

  getAllQuestions(): Promise<BaseResponse<questionsInterface[]>>
  createQuestion(body: questionsInterface): Promise<BaseResponse<questionsInterface>>
  updateQuestion(body: questionsInterface): Promise<BaseResponse<questionsInterface>>
  deleteQuestion(id: string): Promise<BaseResponse<string>>
  getCurrentAnswers(id: string): Promise<BaseResponse<currentAnswers[]>>
  createAnswer(body: currentAnswers): Promise<BaseResponse<currentAnswers>>
  deleteAnswer(id: string): Promise<BaseResponse<string>>
  updateAnswer(body: currentAnswers): Promise<BaseResponse<currentAnswers>>


  getQuestionsOfTask(id: number): Promise<BaseResponse<taskQuestion[]>>
  setQuestionForTask(question: taskQuestion): Promise<BaseResponse<taskQuestion>>

  deleteQuestionTask(id: string): Promise<BaseResponse<string>>

  changeStressCheck(userId: number): Promise<BaseResponse<any>>
  changeCognitiveCheck(userId: number, active: boolean): Promise<BaseResponse<any>>

  getAdminUser(login: string): Promise<BaseResponse<any>>
  getTaskDetail(id: number): Promise<BaseResponse<any>>

  setReviewLog(body: reviewLogAnswers): Promise<BaseResponse<any>>
  getPilotingTask(body: pilotingTaskInterface): Promise<BaseResponse<pilotingTaskInterface>>

  //Получение подсказок для аккардеона в тренажере
  getHint(): Promise<BaseResponse<any>>

  // Работа с голосовым управлением
  getPatientModelVoiceResource(): Promise<BaseResponse<any>>
  getPatientModelVoiceResourceById(id: number): Promise<BaseResponse<any>>
  updatePatientModelVoiceResource(body: PatientModelVoice): Promise<BaseResponse<any>>
  deletePatientModelVoiceResource(id: number): Promise<BaseResponse<any>>
  createPatientModelVoice(body: PatientModelVoice): Promise<BaseResponse<any>>

  generateEmailCode(email: string): Promise<BaseResponse<any>>
  sendTestResultOnEmail(body: sendTestResultOnEmail): Promise<BaseResponse<any>>
  getListOfStages(): Promise<BaseResponse<any>>
  getTestingStageHistory(): Promise<BaseResponse<any>>
  setTestingStageHistory(id: number): Promise<BaseResponse<any>>
  getTypeOfDifficultLevel(): Promise<BaseResponse<any>>
  copyTaskOnConstructor(id: number): Promise<BaseResponse<any>>
  getDoctorsInfoFilteringTests(): Promise<BaseResponse<any>>
  getLegendOption(id: number): Promise<BaseResponse<any>>
  createLegendOption(body: LegendOption): Promise<BaseResponse<any>>
  updateLegendOption(body: LegendOption): Promise<BaseResponse<any>>
  deleteLegendOption(id: number): Promise<BaseResponse<any>>

  getAllRecipe(): Promise<BaseResponse<any>>

  getRecipeDetailsTypes(): Promise<BaseResponse<any>>

  createRecipeTaskDetail(body: RecipeTaskDetail): Promise<BaseResponse<any>>
  deleteRecipeTaskDetail(id: number): Promise<BaseResponse<any>>
  getRecipeTaskDetailByProceedTask(): Promise<BaseResponse<any>>
  getRecipeTaskDetailByTask(id: number): Promise<BaseResponse<any>>
  updateRecipeTaskDetail(body: RecipeTaskDetail): Promise<BaseResponse<any>>

  createRecipeTask(body: RecipeTask): Promise<BaseResponse<any>>
  deleteRecipeTask(id: number): Promise<BaseResponse<any>>
  getRecipeTask(): Promise<BaseResponse<any>>
  getRecipeTaskById(id: number): Promise<BaseResponse<any>>


  getRecipeUserTaskProceed(): Promise<BaseResponse<any>>
  createRecipeUserTaskProceed(body: RecipeTaskProceed[]): Promise<BaseResponse<any>>
  
  getRecipeUserTaskProceedActive(): Promise<BaseResponse<any>>
  createRecipeUserTaskProceedActive(body: {}): Promise<BaseResponse<any>>

  // Работа с диагностическим критерием
  createDiagnosticCriteria(body: {}): Promise<BaseResponse<any>>

  updateDiagnosticCriteria(body: {}): Promise<BaseResponse<any>>

  deleteDiagnosticCriteria(id: number): Promise<BaseResponse<any>>

  getDiagnosticCriteria(id: number): Promise<BaseResponse<any>>

  getAllDiagnosticCriteria(): Promise<BaseResponse<any>>

  // Работа с проявлениями диагностического критерия
  createDiagnosticCriteriaDescription(body: {}): Promise<BaseResponse<any>>

  updateDiagnosticCriteriaDescription(body: {}): Promise<BaseResponse<any>>

  deleteDiagnosticCriteriaDescription(id: number): Promise<BaseResponse<any>>

  getDiagnosticCriteriaDescription(id: number): Promise<BaseResponse<any>>

  getAllDiagnosticCriteriaDescriptions(criteriaId?: number): Promise<BaseResponse<any>>

  // Работа с баллами диагностического критерия
  createDiagnosticCriteriaPointsDescription(body: {}): Promise<BaseResponse<any>>

  updateDiagnosticCriteriaPointsDescription(body: {}): Promise<BaseResponse<any>>

  deleteDiagnosticCriteriaPointsDescription(id: number): Promise<BaseResponse<any>>

  getDiagnosticCriteriaPointsDescription(id: number): Promise<BaseResponse<any>>

  getAllDiagnosticCriteriaPointsDescriptions(criteriaId?: number): Promise<BaseResponse<any>>

  // Работа с диагностическими задачами
  createDiagnosticCriteriaTask(body: {}): Promise<BaseResponse<any>>

  updateDiagnosticCriteriaTask(body: {}): Promise<BaseResponse<any>>

  deleteDiagnosticCriteriaTask(id: number): Promise<BaseResponse<any>>

  getDiagnosticCriteriaTask(id: number): Promise<BaseResponse<any>>

  getAllDiagnosticCriteriaTasks(criteriaId?: number, taskId?: number): Promise<BaseResponse<any>>

  // Работа пользователя с критериями
  createDiagnosticCriteriaUserTask(body: {}): Promise<BaseResponse<any>>

  updateDiagnosticCriteriaUserTask(body: {}): Promise<BaseResponse<any>>

  deleteDiagnosticCriteriaUserTask(id: number): Promise<BaseResponse<any>>

  getDiagnosticCriteriaUserTask(id: number): Promise<BaseResponse<any>>

  getAllDiagnosticCriteriaUserTasks(userTaskProceed?: number): Promise<BaseResponse<any>>

  getDiagnosticCriteriaUserTaskResult(): Promise<BaseResponse<any>>

  getDiagnosticCriteriaUserTaskTextResult(): Promise<BaseResponse<any>>
}

export interface RecipeTask {
  id?: number,
  idRecipe: number,
  idTask: number
}

export interface RecipeTaskDetail {
  autoDate: boolean,
  cell: number,
  dayCount: number,
  id?: number,
  incorrectPoint: number,
  point: number,
  recipeDetailsTypesId: number,
  recipeTaskId: number,
  right: boolean,
  taskId: number,
  value: string,
  x: number,
  y: number
}

export interface sendTestResultOnEmail {
  guid: string
  email: string
  html: string
}

export interface RecipeTaskProceed {
  recipe_taskdetail_id: number
  value: boolean
}

export interface LegendOption {
  id?: number
  htmlText: string
  nameStage: string
  sort: number
  taskId: number | undefined
}

export interface themeInterface {
  id: number,
  nameTheme: string,
}

export interface GetHint {
  key: string
}

export interface getPatientModelVoice {
  key: string
}


export interface reviewLogAnswers {
  ans_1: string
  ans_2: string
  ans_3: string
}

export interface PatientModelVoice {
  id: number | null
  param1: string | undefined,
  param2: string | undefined,
  param3: string | undefined,
  param4: string | undefined,
  patientModelId: number,
  voice: boolean
}

export interface pilotingTaskInterface {
  medicine_id: number
  typeofdifficultlevel_id: number
  universitylevel_id: number
  login: string
  task_id: number[]

}

export interface taskQuestion {
  questUid: string
  stageNumber: number
  taskId: number
}

export interface currentAnswers {
  active: boolean,
  correct: boolean,
  questUid: string,
  sort: number,
  text: string,
  uid: string
  idtheme?: number | null
}

export interface questionsInterface {
  active: boolean
  sort: number
  text: string
  uid: string
  idTheme?: number | null
}

export interface createdSpecialist {
  taskId: number
  anamnesisId: number
  value: string
  point: number
  connectionWithIllnessId: number
  clinicalRelevanceId: number
  incorrectPoint: number
  groupsThemesId: number
  id?: string | number
  points?: number
  connectionwithillnessId?: number
  incorrectPoints?: number
  clinicalrelevanceId?: number
  typeOfInformationLevelId: number
}

export interface models {
  patientId: number,
  modelLink: string
  description: string
  view3dLink: string
}

export interface allPatient {
  id?: number,
  name: string,
  firstName: string,
  secondName: string,
  photo: string,
  age: number,
  gender: string
}

export interface reviewCreate {
  teachers_id: number
  score: number
  feedback: string
}

export interface groupOfResearchInterface {
  active: boolean
  description: string
  incorrectPoint: number
  name: string
  typeofresearchId: number
  uid: string
  id?: number
  parentId?: number
}
export interface treatmentSchemeInterface {
  id: number
  treatmentSchemeId: number,
  point: number,
  textErrors: string,
  textSuccess: string,
  treatmentScheme: string
  listed: any
}
export interface surgicalTreatmentConstructorInterface {
  correctAnswer: number
  id: number
  incorrectAnswer: number
  text: string
  targetuseid: number
  taskId: number
}

export interface depersonalizedRatingInterface {
  dateStart: string
  dateEnd: string
  numberOfEntries: number
  trainerId?: number | undefined
  organisationId?: number | undefined
  studentSpecialityId?: number | undefined
  universityLevelId?: number | undefined
}

export interface nursingCareListInterface {
  correctAnswer: number
  count: null
  countidperiod: null
  countperiod: null
  duration: null
  durationidperiod: null
  durationperiod: null
  groupsThemesId: null
  id: number
  importance: null
  incorrectAnswer: number
  name: string
  targetuseid: number
  taskId: number
  time: null
  timeidperiod: null
  timeperiod: null
}

export interface modesReferenceInterface {
  id?: number;
  value: string;
  description: string;
}

export interface matrixCompetenceInterface {
  values: number;
  doctors_info_id: number;
  kind: string;
  category: string;
  competence_id: number;
  value: string;
}

export interface noneMedicalTreatment {
  id?: number;
  name: string;
  description: string;
}

export interface nursingCareInterface {
  name: string;
  id?: number;
}

export interface createResearchInstrument {
  norm: string;
  value: string;
  connectionwithillnessId: number | undefined;
  clinicalrelevanceId: number | undefined;
  incorrectPoint: number;
  correctPoint: number;
  researchUnit: number;
  taskId: number;
  typeId: number | undefined;
}

export interface get_Medical_Dictionary {
  id: number;
  code: string;
  name: string;
  description: string;
  typeOfMedicalDictionaryId: number;
  typeOfInformationLevel: null;
}
export interface Treatment_Item_Type {
  activeIngredient: string;
  additionalRestrictionId: number;
  alternative: number;
  connectionWithFoodId: number;
  connectionWithSleepId: number;
  correctAnswer: number;
  coursePeriodId: number;
  dayStart: number;
  dosage: number;
  dosageunitid: number;
  factor: number;
  groupOfMedicineId: number;
  groupsThemesId: number;
  id: number;
  importance: number;
  incorrectAnswer: number;
  localitiesId: number;
  numberOfDoses: number;
  quantityCourse: number;
  quantityPerDay: number;
  realeseFormId: number;
  receptionMethodId: number;
  taskId: number;
  timeOfReceiptId: number;
  totalDoses: number;
}

export interface Create_Or_Update_Schedule {
  id?: number;
  name: string;
  targetuseid: number;
  count: number;
  countperiod: number;
  duration?: number;
  durationperiod?: number;
  time?: number;
  timeperiod?: number;
  isScheduleContr: boolean;
  alternativemedictiontypeid: number;
  correctAnswer: number;
  incorrectAnswer: number;
}

export interface Add_Diet {
  name: string;
  time: number;
  timeperiod: number;
  alternativemedictiontypeid: number; //4
  targetuseid: number;
  taskId: number;
  correctAnswer: number;
  incorrectAnswer: number;
}

export interface CreateOrUpdateNotTreatment {
  id?: number;
  name: string;
  alternativemedictiontypeid: number;
  targetuseid: number;
  taskId: number;
  correctAnswer: number;
  incorrectAnswer: number;
}

export interface get_Reference {
  id: number;
  value: string;
}

export interface get_Standart_Alternative_Medication {
  id: number;
  name: string;
  count: null;
  duration: null;
  time: number;
  alternativemedictiontypeid: number;
  targetuseid: number;
  taskId: number;
  correctAnswer: number;
  incorrectAnswer: number;
  importance?: null;
  groupsThemesId?: number;
  timeperiod: number;
  countperiod: null;
  durationperiod: null;
  countidperiod: null;
  durationidperiod: null;
  timeidperiod: number;
  text?: string
}

export interface update_Current_Objective_status {
  id: number;
  active: true;
  taskId: number;
  anamnesisId: number;
  typeOfInformationLevelId: number;
  value: string;
  orderBy: number;
  points: number;
  incorrectPoints: number;
  connectionwithillnessId: null;
  clinicalrelevanceId: null;
  importance: null;
  groupsThemesId: null | number;
  anamnesis: null;
  task: null;
  typeOfInformationLevel: null;
  connectionWithIllness: null;
  clinicalRelevance: null;
  illnessParameters: null;
}

export interface get_Medical_Dictionary_All {
  id: number;
  code: string;
  name: string;
  description: string;
  typeOfMedicalDictionaryId: number;
  typeOfInformationLevel: null;
  unicId: number | null;
}

export interface get_Front_Body_Part_Image {
  id: number;
  partOfBodyId: number;
  inspectionMethodId: number;
  inspectionSideId: number;
  description: string;
  patientModelId: number;
}

export interface get_Inspection_Side {
  id: number;
  value: string;
}

export interface get_Constructor_Additional_Results {
  parentName: string;
  id: number;
  active: boolean;
  taskId: number;
  anamnesisId: number;
  typeOfInformationLevelId: number;
  value: string;
  orderBy: number;
  points: number;
  incorrectPoints: number;
  connectionwithillnessId: number;
  clinicalrelevanceId: number;
  importance: null;
  groupsThemesId: number;
  anamnesis: null;
  task: null;
  typeOfInformationLevel: null;
  connectionWithIllness: null;
  clinicalRelevance: null;
  illnessParameters: null;
}

export interface Get_Test {
  task_id: number;
  stage_number: number;
}
export interface set_Test_Answers {
  session: string;
  answers: setTestAnswersArray[];
}
export interface get_localisations_place {
  id: number;
  value: string;
}

export interface get_Medical_Treatment {
  situationId: number;
  situation: boolean;
  activeIngredient: string;
  additionalRestrictionId: number;
  alternative: number;
  connectionWithFoodId: number;
  connectionWithSleepId: number;
  correctAnswer: number;
  coursePeriodId: number;
  dayStart: number;
  dosage: number;
  dosageunitid: number;
  factor: number;
  groupOfMedicineId: number;
  groupsThemesId: number;
  id: number;
  importance: null;
  incorrectAnswer: number;
  localitiesId: null;
  numberOfDoses: number;
  quantityCourse: number;
  quantityPerDay: number;
  realeseFormId: number;
  receptionMethodId: number;
  taskId: number;
  timeOfReceiptId: number;
  totalDoses: number;
  dosageUnit: {
    id: number;
    value: string;
  };
}

export interface get_Objective_Status {
  id: number;
  active: boolean;
  taskId: number;
  anamnesisId: number;
  typeOfInformationLevelId: number;
  value: string;
  orderBy: number;
  points: number;
  incorrectPoints: null;
  connectionwithillnessId: null;
  clinicalrelevanceId: null;
  anamnesis: null;
  task: null;
  typeOfInformationLevel: null;
  connectionWithIllness: null;
  clinicalRelevance: null;
}

interface setTestAnswersArray {
  quest: string;
  uid: string;
  test_answers: answers[];
}
interface answers {
  answer: string;
  uid: string;
}
export interface bodyGetTest {
  task_id: number;
  stage_number: number;
}

export interface type_Anamnesis {
  id: number;
  name: string;
}

export interface update_Meaning {
  id: number | undefined;
  taskId: number | undefined;
  value: string | undefined;
  anamnesisId: number | undefined;
  connectionWithIllnessId: number | undefined;
  clinicalRelevanceId: number | undefined;
  point: number;
  incorrectPoint: number;
  active: boolean
}

export interface delete_Meaning {
  id: number;
}

export interface get_Objective_Status {
  id: number;
  active: boolean;
  taskId: number;
  anamnesisId: number;
  typeOfInformationLevelId: number;
  value: string;
  orderBy: number;
  points: number;
  incorrectPoints: null;
  connectionwithillnessId: null;
  clinicalrelevanceId: null;
  anamnesis: null;
  task: null;
  typeOfInformationLevel: null;
  connectionWithIllness: null;
  clinicalRelevance: null;
}

export interface get_Current_Objective_Status {
  id: number;
  name: string;
  medicineId: number;
  tag: string;
  scope: string;
  score: number;
  uid: string;
  typeOfDataId: number;
  typeOfInformationLevelId: number;
  anamnesisTypeId: number;
  active: boolean;
  medicine: {
    id: number;
    active: boolean;
    name: string;
    description: string;
    orderBy: number;
    uid: string;
    picture: string;
  };
  typeOfData: {
    id: number;
    code: number;
    name: string;
  };
  anamnesisId: number;
}
export interface get_Excrement_Photo {
  id: number;
  value: string;
}

export interface get_Urine_Status {
  id: number;
  value: string;
}

export interface get_Clinical_Relevance {
  id: number;
  name: string;
}
export interface get_Connection_With_Illness {
  id: number;
  name: string;
  value: string;
  defaultValue?: string;
}

export interface get_Default_Mean_List {
  id?: number;
  active?: boolean;
  taskId?: number;
  anamnesisId?: number;
  typeOfInformationLevelId?: number;
  value?: string;
  orderBy?: number;
  points?: number;
  incorrectPoints?: null;
  connectionwithillnessId?: number | undefined;
  clinicalrelevanceId?: number | undefined;
  anamnesis?: null;
  task?: null;
  typeOfInformationLevel?: null;
  connectionWithIllness?: null;
  clinicalRelevance?: null;
  illnessParameters?: null;
  groupsThemesId: number
}

export interface create_Mean {
  taskId?: number;
  value?: string;
  anamnesisId?: number;
  connectionWithIllnessId?: number;
  clinicalRelevanceId?: number;
  point?: number;
  incorrectPoint?: number;
}

export interface BodyPartImage_GetResponse_NetData {
  id: number;
  partOfBodyId: number;
  inspectionMethodId: number;
  inspectionSideId: number;
  description: string;
}
export interface PointOfView_GetResponse_NetData {
  painfulJoint?: number | null;
  swollenJoint?: number | null;
  id: number;
  partOfBodyId: number;
  inspectionMethodId: number;
  inspectionSideId: number;
  point_x: number;
  point_y: number;
  point_z: number;
  description: string;
  picture: string;
  referenceDescriptionId: number;
  descriptionPatient: string;
  text: string;
  sound: string;
  taskId: number;
  pointsClickStandartEntities?: Array<any>;
  pointsDescriptions?: Array<any>;
  preFillColor: string;
  fillColor: string;
  strokeColor: string;
  showPoint: boolean;
}

export interface InspectionMethod_GetResponse_NetData {
  id: number;
  name: string;
}
export interface PartOfBody_GetResponse_NetData {
  id: number;
  name: string;
  description: string;
}

export interface InspectionSide_GetResponse_NetData {
  id: number;
  value: string;
}
export interface PassiveActiveTreatment_GetResponse_NetData {
  id: number;
  name: string;
}

export interface PassiveMedication_CreateUpdate_NetData {
  id?: number;
  name: string;
  targetuse: string;
  count: number;
  countperiod: string;
  duration?: number;
  durationperiod?: string;
  time?: number;
  timeperiod?: string;
}

export interface PassiveMedication_GetResponse_NetData {
  id?: number;
  name: string;
  targetuseid: number; // id targetuse назначен препарат или противопоказан
  count: number;
  countperiod: number; // id period
  duration?: number;
  durationperiod?: number; // id period
  time?: number;
  timeperiod?: number; // id period
}

export interface ActiveMedication_GetResponse_NetData {
  id?: number;
  name: string;
  targetuseid: number; // id targetuse
  count: number;
  countperiod: number; // id period
  duration?: number;
  durationperiod?: number; // id period
  time?: number;
  timeperiod?: number; // id period
}

export interface Update_Research_Instrument {
  id: number;
  typeId: number;
  taskId: string;
  value: string;
  norm: string;
  researchUnit: string;
  connectionWithIllnessId: number;
  clinicalRelevanceId: number;
  point: number;
  incorrectPoint: number;
}

export interface Constructor_Reference {
  id: number;
  reference: string
}

export interface ActiveMedication_CreateUpdate_NetData {
  id?: number;
  name: string;
  targetuse: string;
  count: number;
  countperiod: string;
  duration?: number;
  durationperiod?: string;
  time?: number;
  timeperiod?: string;
}

export interface AlternativeMedicationType_GetResponse {
  id: number;
  name: string;
}

export interface FoodMedication_CreateUpdate_NetData {
  id?: number;
  name: string;
  time: string;
  timeperiod: string;
  targetuse: string;
}

export interface FoodMedication_GetResponse_NetData {
  id: number;
  name: string;
  time: string;
  timeperiod: number; // id
  targetuseid: number; // id
}

export interface TargetUseType_NetData {
  id: number;
  name: string;
}
export interface ScheduleMedication_CreateUpdate_NetData {
  id?: number;
  name: string;
  targetuse: string;
  count: number;
  countperiod: string;
  duration: number;
  durationperiod: string;
  time: number;
  timeperiod: string;
}

export interface ScheduleMedication_GetResponse_NetData {
  id: number;
  name: string;
  targetuseid: number; // id
  count: number;
  countperiod: number; // id
  duration: number;
  durationperiod: number; // id
  time: number;
  timeperiod: number; // id
}

export interface DietMedication_GetResponse_NetData {
  id: number;
  name: string;
  time: string;
  timeperiod: number; // id
}
export interface DietMedication_CreateUpdateRequest_NetData {
  id?: number;
  name: string;
  time: string;
  timeperiod: string;
}

// Объект содержащий результаты лечения
export interface MedicalResult_ResponseData {
  medicationResults: Array<PrescribedMedicationResult__ResponseData>;
  negativePoints: number;
  positivePoints: number;
  success: boolean;
}

//
export interface NotMedicalMedication_NetData {
  name: string;
  quantityperday: number;
  course: number;
  period: string;
  type: number;
  flag: number;
}

export interface NotMedicalType_ResponseData {
  id: number;
  value: string;
}

export interface NotMedicalFlag_ResponseData {
  id: number;
  value: string;
}

export interface DosageUnit_ResponseData {
  id: number;
  value: string;
}

export interface DietType_ResponseData {
  id: number;
  value: string;
}

export interface PrescribedMedicationResult__ResponseData {
  id?: number;
  description: string;
  points: number;
  correct: boolean;
}

export interface PrescribedMedication__ResponseData {
  active: boolean;
  activeIngredient: string;
  additionalRestrictionId: number;
  connectionWithFoodId: number;
  connectionWithSleepId: number;
  coursePeriodId: number;
  createdatetime: string;
  deletedatetime: null;
  dosage: number;
  groupOfMedicineId: number;
  id: number;
  numberOfDoses: number;
  quantityCourse: number;
  quantityPerDay: number;
  realeseFormId: number;
  receptionMethodId: number;
  timeOfReceiptId: number;
  totalDoses: number;
  updatedatetime: string;
  userTaskProceedId: number;
  dosageunitid: number;
  factor: number;
  situation_id: number;
  situation: boolean;
}

// объект лечения для запросов и ответов
export interface PrescribedMedication__NetData {
  id?: number;
  realeseform: string;
  activeingredient: string;
  groupofmedicine: string;
  dosage: number;
  numberofdoses: string;
  totaldoses: string;
  receptionmethod: string;
  quantityperday: string;
  timeofreceipt: string;
  connectionwithfood: string;
  quantitycourse: number;
  courseperiod: string;
  connectionwithsleep: string;
  additionalrestriction: string;
  dosageunit: string;
  factor: number;
  situation: boolean;
  situation_id?: number;
}

// единицы измерения периода приема лекарства
export interface ReceptionPeriod_NetData {
  id: number;
  value: string;
}

// дополнительные ограничения
export interface AdditionalRestriction {
  id: number;
  restrict: string;
}

// связь лекарства со сном
export interface ConnectionWithSleep {
  id: number;
  value: string;
}

// связь лекарства с приемом пищи
export interface ConnectionWithFood {
  id: number;
  value: string;
}

// время приема лекарств
export interface TimeOfReceipt {
  id: number;
  time: string;
}

// способ приема лекарств
export interface ReceptionMethod {
  id: number;
  method: string;
}

// группа лекарств
export interface GroupOfMedicine {
  id: number;
  name: string;
}

// форма выпуска лекарства
export interface ReleaseForm {
  id: number;
  type: string;
}

export interface TypeOfResearchSearchResult_Response {
  groupOfResearches: Array<GroupOfResearch_ResponseData>;
  typeOfResearches: Array<TypeOfResearch_SearchResponseData>;
}

export interface Diagnosis_ReqData {
  diagnosis: Array<DiagnosisItem_ReqData>;
  isFinished: boolean;
  isProvisional: boolean;
  typeOfPatientStatusId: number;
}

export interface DiagnosisFrom_ResponseData extends Array<Diagnosis_ReqData> { }

export enum AnamnesisUid {
  basic = '421c8d2b-e020-492a-8c6b-46e48602fa5f', // - основное заболевание
  concur = '091e4d8f-2b67-4874-b67d-ed23bfbf70ba', // - 'Конкурирующее заболевание'
  oslozhnenie = '74b89e94-f673-48af-9673-4209a3790b86', // - осложнение
  soputstv = 'ed4c116c-9bcd-4ff9-ab45-d1d78043f48e', // - 'Сопутствующее заболевание',
  patient = '87e8ca06-160d-4837-b558-2572af5f84a5', // - с таким anamnesUid присылать запрос на "запрос пациента"
}

export interface IIllness {
  id?: number;
  anamnesisUid: AnamnesisUid;
  illnessPhaseId: number | '';
  illnessRiskId: number | '';
  illnessSeverityId: number | '';
  illnessStageId: number | '';
  medicalDictionaryCode?: string | '';
  medicalDictionaryId: number | '';
  taskId: number | '' | undefined;
  typeOfInformationLevelId: number | '';
}

export enum PatientStatusCode {
  Light = 'Light',
  Normal = 'Normal',
  Hard = 'Hard',
  Fatal = 'Fatal',
  Die = 'Die'
}

export interface DiagnosisItem_ReqData {
  id?: number;
  code?: string;
  name?: string;
  description?: string;
  typeOfMedicalDictionaryId?: number;
  typeOfInformationLevel?: number;
}

export interface ResearchItem_RequestData {
  groupId: number;
  typeOfResearchId: number;
}

export enum ResearchResultRootGroup {
  Laboratory = 'Лабораторные исследования',
  Instrumental = 'Инструментальные исследования',
}

export interface ResearchResults_ResponseData {
  group: ResearchResultRootGroup;
  penalty: number;
  points: number;
  text: string;
  title: string;
  type: 'String' | 'Image' | 'Number' | 'Boolean' | 'File' | 'Audio' | 'Cord';
  typeOfResearchId: number;
  taskDetailMediaId: number;
}

export interface TypeOfResearch_ResponseData {
  isGroupExist: boolean;
  isTypeExist: boolean;
  active: boolean;
  description: string;
  id: number;
  isChildFree: boolean;
  name: string;
  uid: string;
  parentGroupId?: number; // поле реально не приходит с бека, синтетически его добавляем
}

export interface TypeOfResearch_SearchResponseData extends TypeOfResearch_ResponseData {
  groupsOfResearchIds: Array<number>; // костыльное поле содержащие айди групп в которых содержится этот тип исследования
}

export type GroupOfResearch_ResponseData = {
  isTypeExist: boolean;
  isGroupExist: boolean;
  active: boolean;
  name: string;
  description: string;
  id: number;
  isChildFree: boolean;
  orderAt: number;
  parentId: number;
  typesOfResearch?: Array<TypeOfResearch_ResponseData>;
};

export type CheckJournalHistory_ResponseData = {
  createDate: Date;
  id: number;
  request: SendMessage_RequestData;
  response: SendMessage_ResponseData;
};

export type Task_ResponseData = {
  conscious: boolean
  active: boolean;
  created: string;
  creator: string;
  description: string;
  id: number;
  name: string;
  penalty: number;
  typeOfDifficultLevelId: number;
  uid: string;
  medicineId: number;
  typeOfDifficultLevel: {
    active: boolean;
    code: string;
    coefficient: number;
    id: number;
    name: string;
  };
  patientModel: {
    patient: {
      firstName: string;
      secondName: string;
      gender: string;
      photo: string;
      id: number;
    };
  };
};

export type StudentSpeciality_ResponseData = {
  // active: boolean;
  // description: string;
  // id: number;
  // name: string;
  // orderBy: number;
  // uid: string;
  // picture?: string;
};

export type Medicine_ResponseData = {
  active: boolean;
  description: string;
  id?: number;
  name: string;
  orderBy: number;
  uid: string;
  picture?: string;
};

export interface BaseResponse<T> {
  success: boolean;
  result: T;
  description?: {
    message: string;
    reason: string;
    stacktrace: Array<any>;
  } | null;
}

export type Rating_UserInfo = {
  active: boolean;
  id: number;
  login: string;
  name: string;
  surname: string;
  photo: string;
};

export type Rating_BestUser = {
  user: {
    name: string;
    surname: string;
  };
  penalty: number;
  point: number;
};

export type Rating_Activity = {
  medicineName: string;
  countComplete: number;
  countFailed: number;
};

export type Rating_Line = {
  date: string;
  point: string;
};

export type Constructors_Communication = {
  id?: number;
  medicineId?: number;
  medicineName?: string;
  medicine?: { name?: string };
  name: string;
  score: number;
};

export type Constructors_PatientParams = {
  id?: number;
  name?: string;
  value?: any;
};

export type Constructors_ClinicalRelevance = {
  id?: number;
  name?: string;
};

export type Constructors_ReferenceDescription = {
  id?: number;
  value?: string;
};

export type Constructors_ClinicalDiagnosis = {
  id?: number | null;
  name?: string
  value?: string
};

export type Constructors_ClinicalDiagnosisMedicalDictionary = {
  id?: number | null;
  clinicalDiagnosisId?: string;
  medicalDictionaryId?: string;
};

export type Constructors_ClinicalDiagnosisInformationLevel = {
  id?: number;
  typeOfInformationLevelId: number | '';
  taskId: number | undefined;
  clinicalDiagnosisId: number;
  point: number;
  incorrectPoint: number;
  groupMedicineId: number;
};

export type ClinicalDiagnosisTaskProceed = {
  clinicalDiagnosisId: number;
  typeOfInformationLevelId: number;
};

export type Constructors_ConnectionWithIllness = {
  id?: number;
  name?: string;
};

export type Constructors_UrinePressure = {
  id?: number;
  value?: string;
};

export type Constructors_UrineColor = {
  id?: number;
  value?: string;
};

export type Constructors_UrineImpurity = {
  id?: number;
  value?: string;
};

export type Constructors_UrineSmell = {
  id?: number;
  value?: string;
};

export type Constructors_UrineTransparency = {
  id?: number;
  value?: string;
};

export type Constructors_UrineFoam = {
  id?: number;
  value?: string;
};

export type Constructors_UrineComfort = {
  id?: number;
  value?: string;
};

export type Constructors_UrineAdditionalFenomen = {
  id?: number;
  value?: string;
};

export type Constructors_ExcrementColor = {
  id?: number;
  value?: string;
};

export type Constructors_ExcrementComfort = {
  id?: number;
  value?: string;
};

export type Constructors_ExcrementPhoto = {
  id?: number;
  value?: string;
};

export type Constructors_ExcrementSmell = {
  id?: number;
  value?: string;
};

export type Type_ResearchUnit = {
  id: number;
  value: string;
};

export type Type_ParametersForDefaultResearch = {
  id: number;
  researchDefault: Type_ResearchDefault;
  parametersOfResearch: Type_ParametersOfResearch;
  username: string;
};

export type Type_ResearchDefaultByTypeId = {
  id: number;
  unit: string;
  leftBorder: number;
  rightBorder: number;
  username: string;
  createdAt: string;
  updatedAt: string;
  typeId: number;
  parametersForDefaultResearches: Array<Type_ParametersOfResearch>;
};

export type Type_ResearchDefault = {
  id: number;
  unit: string;
  leftBorder: number;
  rightBorder: number;
  username: string;
  createdAt: string;
  updatedAt: string;
  typeId: number;
  parametersForDefaultResearches?: Array<Type_ParametersForDefaultResearch>;
};
export type Type_ParametersOfResearch = {
  id: number;
  name: string;
  value: string;
};

export type Constructors_Additional = {
  id?: number;
  active?: boolean;
  name: string;
  description: string;
  groupsOfResearch?: any;
};

export type Constructors_Task_Send = {
  id?: number;
  typeOfDifficultLevelId: number;
  name: string;
  description: string;
  patientModelId: number;
  universityLevelId: number;
  medicineId: number;
  studentSpecialityId: number;
  patientStatusId: number;
  conscious: boolean;
  communicationBehalf: string | null;
  idTheme: number;
  generalHealthAssessment: number | string
};

export type Constructors_Task = {
  id?: number;
  typeOfDifficultLevel: {
    code: string;
    name: string;
  };

  typeOfDifficultLevelId: number | '';
  name: string;
  description: string;
  penalty: number;
  patientModelId: number | '';
  patientModel: {
    id: number;
    description?: string;
  };
  patientStatusId: number | '';
  patientStatus: {
    name?: string;
    code: string;
  };
  universityLevelId: number | '';
  universityLevel: {
    name?: string;
    code: string;
  };
  medicineId: number | '';
  studentSpecialityId: number | '';
  typeOfInformationLevelId: number | string;
  generalHealthAssessment: number | string | null
};

export interface BodyPartImage_Props {
  id?: number;
  partOfBody: number;
  inspectionMethod?: number;
  inspectionSide: number;
  description: string;
}

export interface Rheumatology_State {
  id?: number,
  active: boolean,
  taskId: number,
  anamnesisId: number,
  typeOfInformationLevelId: number,
  value: string | undefined,
  orderBy: number,
  points: number,
  incorrectPoints: number,
  connectionwithillnessId: number,
  clinicalrelevanceId: number,
  groupsThemesId: number
}

export interface PointsOfView_Props {
  id?: number;
  partOfBody: number;
  inspectionMethod?: number;
  inspectionSide: number;
  point_x: number;
  point_y: number;
  point_z: number;
  description: string;
  descriptionPatient?: string;
  photo?: string[];
  referenceDescriptionId?: number;
  text?: string;
  sound?: string[];
  task: number;
  video?: string[];
  clinicalRelevanceId?: number;
  connectionWithIllnessId?: number;
  pointsClickStandartEntities?: [
    {
      id: number;
      taskId: number;
      pointsOfViewId: number;
      correctAnswer: number;
      incorrectAnswer: number;
    }
  ];
  painful_joint?: number;
  swollen_joint?: number;
}

export interface Point_Of_View {
  clinicalRelevanceId: number;
  connectionWithIllnessId: number;
  description: string;
  descriptionPatient: string;
  id: number;
  inspectionMethod: number;
  inspectionSide: number;
  partOfBody: number;
  photo: [string];
  pointX: number;
  pointY: number;
  pointZ: number;
  referenceDescriptionId: number;
  sound: [string];
  task: number;
  video: [string];
}

export type TypeOfDifficultLevel_ResponseData = {
  active: boolean;
  code: string;
  coefficient: number;
  id: number;
  name: string;
};

export type University_Level = {
  active: boolean;
  code: string;
  coefficient: number;
  id: number;
  name: string;
};

export type Patient_Model = {
  modelLink: string;
  description: string;
  patientId: number;
  id: number;
  patient: any;
};

export type Type_Of_Patient_Status = {
  active: boolean;
  code: string;
  description: string;
  id: number;
  name: string;
};

export type Type_DescriptionList = {
  description?: string;
  id?: number;
  indicationsForPrescribing?: string;
  indications_for_prescribing?: string;
  interpretation?: string;
  preparation?: string;
  typeId?: number;
  typeid?: number;
};

export type SendMessage_RequestData = {
  languageCode: string;
  unMarkedText: string;
};

export type SendMessage_ResponseData = {
  help?: boolean;
  tdMediaId: number;
  penalty: number;
  points: number;
  text: string;
};

export type TaskProceed_ResponseData = {
  id: number;
  taskId: number;
  points?: number;
  penalty?: number;
  testingStageId: TestingStage;
  task: Task_ResponseData;
};

export type TaskRating_ResponseData = {
  id: number;
  username: string;
  taskId: number;
  statusId: number;
  testingStageId: number;
  description: string;
  grade: number;
  createdAt: any;
};

export enum TestingStage {
  INSPECTION = 1,
  LEGEND = 2,
  CHAT = 3,
  OBJECTIVE_STATUS = 4,
  PROVISIONAL_DIAGNOSIS = 5,
  RESEARCH = 6,
  CONSULTATION = 7,
  EXAMINATION = 8,
  CONCLUSION = 9,
  CLINICAL_DIAGNOSIS = 10,
  DIAGNOSIS = 11,
  DIAGNOSIS_RESULT = 12,
  FINISH = 13,
  HEALING = 14,
  HEALING_RESULT = 15,
  GENERAL_RESULT = 16,
  // STRESS = 14,
}

// export enum TestingStage {
//   INSPECTION = 0,
//   CHAT = 1,
//   OBJECTIVE_STATUS = 2,
//   PROVISIONAL_DIAGNOSIS = 3,
//   RESEARCH = 4,
//   CONSULTATION = 12,
//   EXAMINATION = 5,
//   DIAGNOSIS = 6,
//   DIAGNOSIS_RESULT = 7,
//   HEALING = 8,
//   HEALING_RESULT = 9,
//   GENERAL_RESULT = 10,
//   FINISH = 11,
// }

export type Unfinished_ResponseDate = {
  empty: boolean;
  present: boolean;
};

export type JournalHistory_ResponseData = {
  createDate: Date;
  id: number;
  request: SendMessage_RequestData;
  response: SendMessage_ResponseData;
};

export type AuthenticateRequest = {
  username: string;
  password: string;
  rememberMe: boolean;
};

export type RegistrationRequest = {
  login: string;
  email: string;
  password: string;
};

export type JwtToken = {
  id_token: string;
};

export interface Communication_ResponseData {
  pointsForUndone: number;
  countOfUndone: number;
  rightQuestionPoints: number;
  wrongQuestionPoints: number;
}

export interface ResultElement_ResponseData {
  name: string;
  points: number;
  correct: boolean;
}

export interface DiagnosisResultElement_ResponseData extends ResultElement_ResponseData {
  caption: string;
  captionOrder: number;
}

export interface GenericTaskResults_ResponseData<T extends ResultElement_ResponseData> {
  pointsForUndone: number;
  countOfUndone: number;
  elements: T[];
}

export interface TaskResult_ResponseData {
  totalResult: boolean | null;
  communication: Communication_ResponseData;
  objectiveStatus: GenericTaskResults_ResponseData<ResultElement_ResponseData>;
  diagnosis: GenericTaskResults_ResponseData<DiagnosisResultElement_ResponseData>;
  research: GenericTaskResults_ResponseData<ResultElement_ResponseData>;
  patientState: GenericTaskResults_ResponseData<ResultElement_ResponseData>;
}

export interface DiagnosisTypes_ResponseData {
  id: InformationLevelId_DiagnosisType;
  description: string;
  typeOfSource: TypeOfSource;
}

export enum TypeOfSource {
  TypeOfInformationLevel = 'TypeOfInformationLevel',
}

export interface CompetenceType {
  id?: number;
  value: string;
}

export interface SpecialtiesInterface {
  id?: number;
  doctors_info_id?: string
  type?: string
  doctors_info_name?: string
}

// хардкод на айдишники типов диагнозов, обязательный только 4 (основной)
export type InformationLevelId_DiagnosisType = 4 | 5 | 6 | 7;
export const InformationLevelId_DiagnosisPrimary: InformationLevelId_DiagnosisType = 4;

export const conditionCodeToConditionId = new Map<PatientStatusCode, number>([
  [PatientStatusCode.Light, 1],
  [PatientStatusCode.Normal, 2],
  [PatientStatusCode.Hard, 3],
  [PatientStatusCode.Fatal, 4],
]);

export const conditionIdConditionCode = new Map<number, PatientStatusCode>([
  [1, PatientStatusCode.Light],
  [2, PatientStatusCode.Normal],
  [3, PatientStatusCode.Hard],
  [4, PatientStatusCode.Fatal],
]);

// export interface HintInterface {
//   id: number
//   key: string
//   shortValue: string
//   value: string
// }
